import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-v4';
import { stripeWalletHistory, stripeRegulatoryReceipt } from 'actions/ledger';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import formatDollars from 'helpers/formatDollars';
import moment from 'moment';
import onboardingStatus from 'helpers/walletOnboardingStatus';
import axios from 'axios';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import windowOpener from '../../../../../helpers/windowOpener';

const WalletHistoryItems = createTableItems(
    ({
        funding_request_id,
        created,
        description,
        invoice_number,
        credit_amount = undefined,
        debit_amount = undefined,
        wallet_balance = '0',
        transaction_category,
        obligation_id,
    }) => {
        return [
            moment(created).format('l'),
            description,
            transaction_category === 'STRIPE_WALLET_DEPOSIT' ? (
                invoice_number
            ) : (
                <Link
                    to={`/haul-pay/${funding_request_id}/transaction`}
                    key={funding_request_id}
                >
                    {invoice_number}
                </Link>
            ),
            credit_amount ? formatDollars(credit_amount) : '',
            debit_amount ? formatDollars(debit_amount) : '',
            wallet_balance ? formatDollars(wallet_balance) : '',
            <Link
                key={obligation_id}
                onClick={async () => {
                    try {
                        const { regulatory_receipt_url } =
                            await stripeRegulatoryReceipt(obligation_id);
                        if (regulatory_receipt_url) {
                            windowOpener(regulatory_receipt_url);
                        } else {
                            alert("No receipt found.");
                        }
                    } catch (error) {
                        alert("Error getting receipt.")
                    }
                }}
            >
                <ReceiptOutlinedIcon style={{ color: '#000000' }} />
            </Link>,
        ];
    }
);

export default function WalletHistoryTable(props) {
    const [walletHistory, setWalletHistory] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [nextPage, setNextPage] = useState('');

    useEffect(() => {
        async function asyncFunction() {
            try {
                setIsFetching(true);
                const { results, next } = await stripeWalletHistory();
                setWalletHistory(results);
                setNextPage(next);
            } catch (err) {}
            setIsFetching(false);
        }
        props.onboardingStatus === onboardingStatus.ACTIVE && asyncFunction();
    }, []);

    const fetchNext = useCallback(async () => {
        try {
            setIsFetching(true);
            const res = await axios(nextPage);
            const { results, next } = res.data;
            setWalletHistory(walletHistory.concat(results));
            setNextPage(next);
        } catch (err) {}
        setIsFetching(false);
    }, [nextPage]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ResponsiveTable
                containerProps={{
                    className: 'col-fill',
                }}
                placeholder="No results."
                headers={[
                    'Date',
                    'Description',
                    'Invoice',
                    'Credit',
                    'Debit',
                    'Balance',
                    '',
                ]}
                items={walletHistory}
                isFetching={isFetching}
                {...WalletHistoryItems}
            />
            {nextPage && Boolean(walletHistory.length) && (
                <button
                    style={{
                        margin: 20,
                        width: 150,
                        alignSelf: 'end',
                    }}
                    className="btn btn-orange"
                    onClick={fetchNext}
                >
                    Load More
                </button>
            )}
        </div>
    );
}
