import axios from 'axios';

export const ledgerWalletHistory = async params => {
    const url = `/factoring/ledger_wallet/history/`;
    const options = {
        url,
        method: 'GET',
        params,
        ...(params ? {
            responseType: 'blob'
        }: {}),
    };
    const response = await axios(options);
    return response.data;
};

export const ledgerWalletPendingTransactions = async () => {
    const url = `/factoring/ledger_wallet/pending/`;
    const options = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const ledgerWalletBalance = async () => {
    const url = `/factoring/ledger_wallet/balance/`;
    const options = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const depositReceivables = async data => {
    const options = {
        url: `/factoring/deposit/`,
        method: "POST",
        data
    };
    const response = await axios(options);
    return response.data;
};

export const fetchStripeOnboarding = async () => {
    const url = `/cmftstripe/onboarding/`;
    const options = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const postStripeOnboarding = async data => {
    const url = `/cmftstripe/onboarding/`;
    const options = {
        url,
        method: 'POST',
        data
    };
    const response = await axios(options);
    return response.data;
};

export const stripeDepositMoney = async data => {
    const url = `/cmftstripe/deposit/`;
    const options = {
        url,
        method: 'POST',
        data
    };
    const response = await axios(options);
    return response.data;
};

export const stripeWalletPendingTransactions = async () => {
    const url = `/cmftstripe/pending_transactions/`;
    const options = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const stripeWalletBalance = async () => {
    const url = `/cmftstripe/balance/`;
    const options = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const stripeWalletHistory = async params => {
    const url = `/cmftstripe/transactions/`;
    const options = {
        url,
        method: 'GET',
        params,
        ...(params ? {
            responseType: 'blob'
        } : {}),
    };
    const response = await axios(options);
    return response.data;
};

export const stripeAddBankAccount = async companyId => {
    const options = {
        url: `/cmftstripe/add_bank_account/`,
        method: "POST",
        data: {
            company_id: companyId
        }
    };
    const response = await axios(options);
    return response.data;
};

export const fetchStripeAccount = async () => {
    const url = `/cmftstripe/account/`;
    const options = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const stripeAddExternalBankAccount = async (paymentManagerId, accountId) => {
    const options = {
        url: `/cmftstripe/accounts/${accountId}`,
        method: "PATCH",
        data: {
            stripe_external_bank_account_id: paymentManagerId
        }
    };
    const response = await axios(options);
    return response.data;
};

export const confirmMicroDepositBankAccount = async code => {
    const options = {
        url: `/cmftstripe/micro_deposit/`,
        method: "POST",
        data: {
            code
        }
    };
    const response = await axios(options);
    return response.data;
};

export const stripeRegulatoryReceipt = async (obligationId) => {
    const url = `/cmftstripe/regulatory_receipt/`;
    const options = {
        url,
        method: 'GET',
        params: {"obligation_id": obligationId}
    };
    const response = await axios(options);
    return response.data;
};
