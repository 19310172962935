export default (link, inNewTab=true ) => {
  const element = document.createElement("a");
  element.href = link;
  if (inNewTab) {
    element.target = "_blank";
  }
  document.body.appendChild(element);
  element.click();
  element.remove();
};
