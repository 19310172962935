import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card } from 'material-ui/Card';
import colors from 'styles/colors.json';
import AddMoneyButton from './components/AddMoneyButton';
import WalletBalanceCard from './components/WalletBalanceCard';
import WalletHistoryTable from './components/WalletHistoryTable';
import WalletPendingTransactions from './components/WalletPendingTransactions';
import Header from 'components/pure/Header';
import WithdrawButton from './components/WithdrawButton';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';
import AddStripeBankAccount from '../../../pure/AddStripeBankAccount';
import onboardingStatus from 'helpers/walletOnboardingStatus';
import MaterialIcon from 'components/pure/MaterialIcon';
import WalletContext from '../context/WalletContext';
import useStripeOnboarding from '../../../hoc/useStripeOnboarding';
import Tabs from '../../../pure/Tab';

export default function BrokerStripeWallet(props) {
    const { fetchWalletPendingTransaction, reload } = useContext(WalletContext);
    const { accountSettings, handleContinue, statusMapping, isGenerateLink } =
        useStripeOnboarding();
    return (
        <div style={{ position: 'relative' }}>
            {/* Banner at the top */}
            <div
                style={{
                    color: '#000000',
                    padding: '10px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }}
            >
                Effective February 12, 2025, all wallet accounts have been transferred to Fifth Third Bank. Please use your new account and routing numbers for any off-platform WIRE or ACH transfers.
                <br />
                If you haven’t received your updated account details yet, kindly email us at <a href="mailto:support+walletdeposit@haulpay.io">support+walletdeposit@haulpay.io</a> for assistance.
            </div>
            <div
                className={
                    [
                        onboardingStatus.EXTERNAL_ACCOUNT_REQUIRED,
                        onboardingStatus.PENDING,
                        onboardingStatus.ONBOARDING_REQUIRED,
                        onboardingStatus.DELETED,
                        onboardingStatus.ONBOARDING_REVIEW,
                    ].includes(accountSettings.onboardingStatus) && 'blurred'
                }
            >
                {accountSettings.onboardingStatus && (
                    <div>
                        <div
                            className="col-xs-12 clearfix"
                            style={{ margin: 10 }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    alignContent: 'center',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <div style={{ flexGrow: 0.33 }} />
                                <div
                                    style={{
                                        flexGrow: 0.33,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <WalletBalanceCard
                                        onboardingStatus={
                                            accountSettings.onboardingStatus
                                        }
                                    />
                                </div>
                                <div style={{ flexGrow: 0.33 }}>
                                    {accountSettings.onboardingStatus ===
                                        onboardingStatus.ACTIVE && (
                                        <>
                                            {accountSettings.depositEnabled && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flex: 1,
                                                        alignContent: 'center',
                                                        justifyContent:
                                                            'flex-end',
                                                        marginRight: '20',
                                                    }}
                                                >
                                                    <AddMoneyButton
                                                        fetchWalletPendingTransaction={
                                                            fetchWalletPendingTransaction
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {false &&
                                                accountSettings.withdrawalEnabled && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            alignContent:
                                                                'center',
                                                            justifyContent:
                                                                'flex-end',
                                                            paddingTop: 10,
                                                            paddingRight: 10,
                                                        }}
                                                    >
                                                        <WithdrawButton />
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Tabs
                            style={{ marginTop: 20 }}
                            tabs={[
                                {
                                    title: 'Pending Transactions',
                                    component: WalletPendingTransactions,
                                    props: {
                                        onboardingStatus:
                                            accountSettings.onboardingStatus,
                                    },
                                },
                                {
                                    title: 'Statement History',
                                    component: WalletHistoryTable,
                                    props: {
                                        onboardingStatus:
                                            accountSettings.onboardingStatus,
                                    },
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
            <div className="centered" id="unBlurred">
                <div>
                    {accountSettings.onboardingRequired &&
                        accountSettings.onboardingStatus ===
                            onboardingStatus.ONBOARDING_REQUIRED && (
                            <Card
                                style={{ borderRadius: 10 }}
                                containerStyle={{
                                    minWidth: 300,
                                    minHeight: '30%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    className="alert"
                                    style={{ width: '100%' }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <MaterialIcon
                                            name="account_balance_wallet"
                                            size={50}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'column',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <p
                                            style={{ padding: 20 }}
                                            id="unBlurred"
                                        >
                                            Please first complete the onboarding
                                            process.
                                        </p>
                                        <BigOrangeSubmitButton
                                            submitting={isGenerateLink}
                                            className="btn btn-orange"
                                            style={{ display: 'block' }}
                                            type="submit"
                                            onClick={handleContinue}
                                            message="Continue"
                                        />
                                    </div>
                                </div>
                            </Card>
                        )}
                    {statusMapping[accountSettings.onboardingStatus] && (
                        <Card
                            style={{ borderRadius: 10 }}
                            containerStyle={{
                                minWidth: 300,
                                minHeight: '30%',
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="alert" style={{ width: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <MaterialIcon
                                        name={
                                            statusMapping[
                                                accountSettings.onboardingStatus
                                            ]?.icon
                                        }
                                        size={50}
                                        style={{
                                            color: statusMapping[
                                                accountSettings.onboardingStatus
                                            ]?.color,
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'column',
                                        alignContent: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <p
                                        style={{ padding: 20 }}
                                        className={`text-${statusMapping[accountSettings.onboardingStatus]?.alertType}`}
                                        id="unBlurred"
                                    >
                                        {
                                            statusMapping[
                                                accountSettings.onboardingStatus
                                            ]?.text
                                        }
                                    </p>
                                </div>
                            </div>
                        </Card>
                    )}
                    {accountSettings.onboardingStatus ===
                        onboardingStatus.EXTERNAL_ACCOUNT_REQUIRED && (
                        <Card
                            id="external_bank_account"
                            style={{ borderRadius: 10 }}
                            containerStyle={{
                                minWidth: 300,
                                minHeight: '30%',
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="alert" style={{ width: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <MaterialIcon
                                        name="account_balance"
                                        size={50}
                                        style={{
                                            color: colors.SUCCESS,
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'column',
                                        alignContent: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <p
                                        style={{ padding: 20 }}
                                        className="text-success"
                                        id="unBlurred"
                                    >
                                        Please first add a bank account to your
                                        wallet.
                                    </p>
                                    <AddStripeBankAccount
                                        accountId={accountSettings.accountId}
                                        context={WalletContext}
                                        isBroker={true}
                                    />
                                </div>
                            </div>
                        </Card>
                    )}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    marginTop: '2.5rem',
                }}
            >
                <footer
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        margin: 0,
                        gap: '1.5rem',
                        paddingTop: '1em',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#1B1B1B',
                        borderTopWidth: 1,
                        borderTopStyle: 'solid',
                        borderTopColor: '#fff',
                    }}
                >
                    <div
                        style={{
                            maxWidth: '90%',
                            margin: '0 auto',
                            width: '100%',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: '600',
                                fontSize: '1.2rem',
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            To report a persistent issue or any disputes you can
                            email our support team <a href="mailto:support@haulpay.io">here</a>{' '}
                            for assistance. <br/>
                            HaulPay partners
                            with Stripe Payments Company for money transmission
                            services and account services with funds held at
                            Fifth Third Bank N.A., Member FDIC.
                        </p>
                    </div>
                </footer>
            </div>
        </div>
    );
}
